import React, { useState, useEffect } from 'react';
import './NewsListing.scss'

const NewsItem = ({ contentID, title, content, url, href, target }) => {
    return(
        <section key={contentID} className="news_item">
            <img src={url} alt="" className="news_item_img"/>
            <div className="news_item_title">{title}</div>
            <div className="news_item_content" dangerouslySetInnerHTML={{__html: !!content ? content : ''}} ></div>
            <a className="news_item_readmore" href={href} target={target}>
                <span>Read More</span>
            </a>
        </section>
    );
}

const NewsListing = ({ newslist }) => {
    const newsitems = newslist.items.map( c => {
        return {
            contentID: c.contentID,
            ...c.fields,
            ...c.properties
        }
    }).sort((a,b) => new Date(b.modified) - new Date(a.modified));

    const newsitem_cols = [newsitems.filter((value, index) => !(index % 2)), newsitems.filter((value, index) => (index % 2))];

    return(
        <section className="newslist">
            {
                newsitems && <div className="newsitem_mobile">{ newsitems.map((item, idx) => <NewsItem key={idx} {...item} {...item.image} {...item.link}  /> ) }</div>
            }
            
            {
                newsitem_cols && newsitem_cols.map((col, idx) => <div key={idx} className="newsitem_desktop"> {
                                    col.map((item, idx) => <NewsItem key={idx} {...item} {...item.image} {...item.link} />) } 
                        </div>) 
            }
            
            

        </section>
    );
}

export default NewsListing;
