//Our Agility Modules
import RichTextArea from './modules/RichTextArea'
import PostsListing from './modules/PostsListing'
import PostDetails from './modules/PostDetails'
import Jumbotron from './modules/Jumbotron'
import Connect from './components/Connect/Connect'
import Features from './components/Features/Features';
import FloorDetail from './components/FloorDetails/FloorDetails';
import GlobalHeader from './components/GlobalHeader/GlobalHeader';
import GlobalFooter from './components/GlobalFooter/GlobalFooter';
import HeroSlider from './components/HeroSlider/HeroSlider';
import Highlights from './components/Highlights/Highlights';

//Our Agility PageTemplates
import OneColumnTemplate from './pageTemplates/OneColumnTemplate'

export default {
    /*guid: 'd26acb91-u', //Set your guid here
    fetchAPIKey: 'defaultlive.591e572784018b71ebcdf757f3997488624b82d33c939eb679f7287350c4fc48', //Set your fetch apikey here
    previewAPIKey: 'defaultpreview.e34401092da9c3ba26a329b9911f6c0a410ec58609add461fea86a23c8f2da9c', //set your preview apikey
    */
    guid: 'de258fde', 
    previewAPIKey: 'defaultpreview.b367b6d72df19272aba2d2475d26c9c5abeb4d5761f627733d12aa7a1fd1b5ff', 
    fetchAPIKey: 'defaultlive.f47b333066037e069626f12068a608af1776b57abe5f5b80c72f17a4e93f3b4b',
    languageCode: 'en-us',
    channelName: 'website',
    isPreview: false,
    caching: {
        maxAge: 0
    },
    moduleComponents: {
        RichTextArea,
        PostsListing,
        Jumbotron,
        PostDetails
    },
    pageTemplateComponents: {
        OneColumnTemplate
    }
}