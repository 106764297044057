import React from 'react';
import { useInView } from 'react-hook-inview'
import _ from 'lodash';
import AgilityModules from './AgilityModules'
import JumplinksBar from './Jumplink/JumplinksBar';

const InViewComponent = ({children}) => {
    const [ref, isVisible] = useInView({
        threshold: typeof window !== 'undefined' && window.width > 768 ? 0.3 : 0.1,
    });
    return (<div className={`inview-${isVisible}`} ref={ref}>{children}</div>)
}

const ZoneRenderer = ({agility, zone, zoneName, newslist, modalImage, setModalImage, setZoom }) => {
    const jumplinks = zone.filter( m => m.module === 'Jumplink');
    return zone.map( m => {
            let CurrentComponent = AgilityModules.Raw;
            if (AgilityModules.hasOwnProperty(m.module)) {
                CurrentComponent = AgilityModules[m.module];
            }
            return <InViewComponent key={m.item.contentID}><CurrentComponent agility={agility} setZoom={setZoom} setModalImage={setModalImage} newslist={newslist} componentName={m.module} contentID={m.item.contentID} {...m.item.fields} {...m.item.properties} jumplinks={jumplinks} /></InViewComponent>;    
            } )
};

export default ZoneRenderer;
