import React from 'react';
import './TitleandText.scss';

const TitleandText = ({longTitle, content, layout, background}) => {

    const backgroundColor = background;
    const Tag = '#dfe7ef' === backgroundColor ? 'h2' : 'h4';
    return(
        <section className={`titleandtext ${layout} dark-text-${'#dfe7ef' === backgroundColor }`} style={{backgroundColor}}>
            <div className="titleandtext_title">
                <div className="titleandtext_title_inner"><Tag>{longTitle}</Tag></div>
            </div>
            <div className="titleandtext_content" >
                <div className="titleandtext_content_inner" dangerouslySetInnerHTML={{__html: !!content ? content : ''}}></div>
            </div>
        </section>
    );
}

export default TitleandText;
