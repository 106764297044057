import React, {useState} from 'react';
import close from './close.svg';
import hamburger from './menu.svg';
import mobileLogo from './mobilelogo.svg';

import './GlobalHeader.scss';


const MobileHeader = ({ sitemapNested, leftLink, rightLink, logo, logoMobile }) => {
    const [open, setOpen] = useState(false);
    return(
        <>
            <nav className={`header-mobile open-${open}`}>
                {!open && <div className="navbar">
                    <a href="/" className="logo">
                        <img src={mobileLogo} alt="Toggle Menu"/>
                    </a>
                    <div onClick={ e => {setOpen(!open);} } className={`toggleMenu isopen-${open}`}>
                        <img src={hamburger} alt="Toggle Menu"/>
                    </div>
                </div>}
                {open && 
                    <div className="panel">
                        <div className="close-container">
                            <div onClick={ e => {setOpen(!open);} } className='close-panel'>
                                <img src={close} alt="Toggle Menu"/>
                            </div>
                        </div>
                        <div className="logoandlinks-mobile">
                            <img src={logoMobile.url} alt="logo"/>
                            <div className="logoandlinks">
                                <a className="link-btn leftlink" href={leftLink.href} target={leftLink.target}>{leftLink.text}</a>
                                <a className="link-btn rightlink" href={rightLink.href} target={rightLink.target}>{rightLink.text}</a>
                            </div>
                            
                        </div>
                        <ul className="mobileChildren">
                            {sitemapNested.map( (item,idx) => (<li key={idx}>
                                <a href={item.path}>{item.title} </a>
                            </li>))}
                        </ul>
                    </div>
                    }
            </nav>
        </>
    );
}

const DesktopHeader = ({ sitemapNested, leftLink, rightLink, logo }) => {
    
    return (
        <>
            <div className="logoandlinks">
                <a className="link-btn leftlink" href={leftLink.href} target={leftLink.target}>{leftLink.text}</a>
                <a className="desktop-logo" href="/#" target={logo.target}><img src={logo.url} alt="logo"/></a>
                <a className="link-btn rightlink" href={rightLink.href} target={rightLink.target}>{rightLink.text}</a>
            </div>
            <div className="desktopMenu">
                <ul className="navpart">
                    {sitemapNested.map( page => page?.visible?.menu && <li key={page.pageID} >
                        <a href={page.path}>{page.title}</a>
                    </li>)}
                </ul>
            </div>
        </>
    );
}


const GlobalHeader = ({ sitemapNested, fields }) => {
    const headerReady = true;
    return (
        <header>
            <div className={`animate-${headerReady}`}>
                <DesktopHeader sitemapNested={sitemapNested} {...fields} />
                <MobileHeader sitemapNested={sitemapNested} {...fields} />  
            </div>
        </header>
    );
}

export default GlobalHeader;
