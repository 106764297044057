import Connect from './Connect';
import Features from './Features';
import FloorDetails from './FloorDetails';
import GlobalHeader from './GlobalHeader';
import HeroSlider from './HeroSlider';
import Highlights from './Highlights';
import Jumplink from './Jumplink';
import JumplinksBar from './Jumplink/JumplinksBar';
import MainImage from './MainImage';
import NewsListing from './NewsListing';
import PageTitle from './PageTitle';
import RichTextArea from './RichTextArea';
import TextwithImageToggle from './TextwithImageToggle';
import TitleandText from './TitleandText';
import TitleandTextWithAsset from './TitleandTextWithAsset';
import TitlewithImageandLegend from './TitlewithImageandLegend';
import Raw from './Raw';
import CookieFooter from './CookieFooter';

const AgilityModules = {
  Connect,
  CookieFooter,
  Features,
  FloorDetails,
  GlobalHeader,
  HeroSlider,
  Highlights,
  Jumplink,
  JumplinksBar,
  MainImage,
  NewsListing,
  PageTitle,
  RichTextArea,
  TextwithImageToggle,
  TitleandText,
  TitleandTextWithAsset,
  TitlewithImageandLegend,
  Raw
}

export default AgilityModules;
