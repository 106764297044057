import { agility } from '@agility/content-fetch';
import React, { useState, useEffect } from 'react';
import ZoomableAsset from './../ZoomableAsset';
import './TextwithImageToggle.scss';

const TextwithImageToggle = ({ backgroundImg, images, content, modalImage, setModalImage, setZoom }) => {
    const [toggles, setToggles] = useState(false);
    const [toggledImg, setToggledImg] = useState(false);
    const [slideNum, setSlideNum] = useState(false); 

    useEffect( () => {
        async function fetchToggles() {
            if (typeof window !== 'undefined')   {
                var x = setTimeout(async function() {
                    if (!!toggles ) {
                    } else if (!!window._riverbend && !toggles && !toggledImg) {
                        const _toggles = await agility.client.getContentList(images.referencename);
                        setToggles(_toggles.items);
                        setToggledImg(_toggles.items[0].fields.image.url);
                        setSlideNum(0);
                    }
                    else {
                        setToggles(images);
                        setToggledImg(images[0].fields.image.url);
                        setSlideNum(0);
                    }
                }, 500);
            }
        }
        fetchToggles();
    }, []);

    useEffect( () => {
        if(typeof window !== 'undefined' && typeof slideNum === 'number' && !!toggledImg) {
            if(slideNum === toggles.length - 1) {
                setTimeout(function() {
                    setToggledImg(toggles[0].fields.image.url);
                    setSlideNum(0);
                }, 2000);
            } else {
                setTimeout(function() {
                    setToggledImg(toggles[slideNum + 1].fields.image.url);
                    setSlideNum(slideNum + 1);
                }, 2000);
            }
        }
    }, [slideNum]);

    const onClickHandler = (url) => {
        setToggledImg(url);
    }

    return (
        <section className="text_image_toggle">
            <figure className="background_img">
                <img src={backgroundImg.url} alt=""/>
            </figure>
            <div className="text_image_toggel_content">
                <div className="text_image_toggel_content_inner" dangerouslySetInnerHTML={{__html: !!content ? content : ''}}></div>
            </div>
            <div className="image_toggle">
                <div className="toggle_image">
                    <ZoomableAsset  modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom}  url={toggledImg} zoomImage={'false'} />
                </div>
                { toggles && <div className="toggle_buttons">
                    { toggles.map( toggle => 
                        <div key={toggle.contentID} className={`toggle_button toggle-${toggle.fields.image.url == toggledImg}`} onClick={ e => onClickHandler(toggle.fields.image.url)}>{toggle.fields.image.label}</div>
                    )}
                </div> }
            </div>
        </section>
    );
}


export default TextwithImageToggle;
