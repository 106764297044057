import React, { useState } from 'react';
import './MainImage.scss';

const MainImage = ({ mainImage }) => {
    const [ imagesReady, setImagesReady ] = useState(false);
    const _handleLoadHeader = () => { setImagesReady(true);}

    return (<section className={`animate-${imagesReady} main-image`} onLoad={_handleLoadHeader}>
        <figure>
            <img src={`${mainImage.url}`} alt=""/>
        </figure>
    </section>);
}

export default MainImage;
