import React, {useState, useEffect, useRef} from 'react'
import _ from 'lodash';
import './components/App.scss';
import { useInView } from 'react-hook-inview';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { StickyContainer, Sticky } from 'react-sticky';
import PreloadImage from 'react-preload-image'
import ZoneRenderer from './components/ZoneRenderer';
import GlobalHeader from './components/GlobalHeader'
import GlobalFooter from './components/GlobalFooter'
import close from './components/ZoomableAsset/close.svg';
import plus from './components/ZoomableAsset/plus.png';
import minus from './components/ZoomableAsset/minus.png';
import SEO from './SEO';

const Modal = ({ url, target, label, setModalImage, pixelHeight, pixelWidth, withTransform=true }) => {
    const isVerticalImage = parseInt(pixelHeight) > parseInt(pixelWidth);
    const [loaded, setLoaded] = useState(false);
    return withTransform ? (
        <TransformWrapper options={{centerContent: true}}>
        {({ zoomIn, zoomOut, resetTransform, scale, setScale, ...rest }) => {
            if (!loaded) {
                setTimeout(() => { resetTransform(); setLoaded(true); }, 300);
            }
            return (<React.Fragment>
                <div className="modalContainer">
                    <div className="modal_buttons">
                        <div onClick={ e => {
                            setModalImage(false)
                        }} className="modalbtn close"><img src={close} alt=""/></div>
                        <div onClick={zoomIn} className="modalbtn zoomin"><img src={plus} alt=""/></div>
                        <div onClick={zoomOut} className="modalbtn zoomout"><img src={minus} alt=""/></div>
                    </div>
                    <figure className={`modalimage is-vertical-${isVerticalImage}`}>
                        <TransformComponent>
                            <PreloadImage
                                className="lazyImage"
                                src={`${url}?w=2000&h=2000&c=2`}
                                lazy
                            />
                        </TransformComponent>
                    </figure>
                    
                </div>
            </React.Fragment>
        )}}
    </TransformWrapper>) : <div>
    <React.Fragment>
        <div className="modalContainer">
            <div className="modal_buttons">
                <div onClick={ e => {
                    setModalImage(false)
                }} className="modalbtn close"><img src={close} alt=""/></div>
            </div>
            <figure className={`modalimage is-vertical-${isVerticalImage}`}>
            <iframe width="300" height="150" class="iframes" id="iframe" src="https://oxblue.com/open/OxFord-Property/Riverbend-Business-Park" frameborder="0" webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen" allowfullscreen="allowfullscreen"></iframe>
            </figure>
            
        </div>
    </React.Fragment>
    </div>
}

const InViewGlobalComponent = ({children}) => {
    const [ref, isVisible] = useInView({
        threshold: 0.1,
    });
    return (<div className={`inview-${isVisible}`} ref={ref}>{children}</div>)
}


//todo: replace stuff
const App = (stuff /*{zones, sitemap, thisSiteMapItem, sitemapNested, globalHeader, globalFooter, newslist, isContactPage }*/) => {
    const {agility, currentPage, sitemap, thisSiteMapItem, sitemapNested, isPreview, globalHeader, globalFooter, newslist, isContactPage} = stuff;
    const zones = currentPage.zones;
    const [modalImage, setModalImage] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [ headerReady, setHeaderReady ] = useState(false);

    if (typeof window !== 'undefined') {
        window.setModalWithIframe = function(url) {
            setModalImage({withTransform: false, target:false, label:false, pixelHeight:false, pixelWidth:false, url: url});
        }
    }

    return (    
    <div>
        <SEO  {...currentPage} {...currentPage?.seo} />
        <InViewGlobalComponent><GlobalHeader sitemapNested={sitemapNested} headerReady={headerReady} {...globalHeader} /></InViewGlobalComponent>
        <div className="content-container">
        {!!zones && Object.keys(zones).map( zoneName => !!zones[zoneName] && !!zoneName && <ZoneRenderer agility={agility} modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} zone={zones[zoneName]} zoneName={zoneName} key={zoneName} newslist={newslist} /> )}
        </div>
        <InViewGlobalComponent><GlobalFooter agility={agility} {...globalFooter.fields} isContactPage={isContactPage} /></InViewGlobalComponent>
        {!!modalImage && <Modal setModalImage={setModalImage} setZoom={setZoom} zoom={zoom} {...modalImage} /> }
    </div>);
}

export default App;
