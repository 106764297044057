import React, { useState, useEffect } from 'react';
import './GlobalFooter.scss';


const FooterConnect = ({connect_bgImg, leasingDetails, href, target, text }) => {
    return(
        <div className="footer_connect">
            <figure className="footer_connect_background">
                <img src={`${connect_bgImg}?w=1400`} alt=""/>
            </figure>
            <div className="footer_connect_inner">
                <span className="connect_leasing_details" dangerouslySetInnerHTML={{__html: !!leasingDetails ? leasingDetails : ''}}></span>
                <a className="button button_revert" href={href} target={target}>{text}</a>
            </div>
        </div>
    );
}

const GlobalFooter = ({agility, leasingDetails, connectLink, connect_bgImg, isContactPage,
                        logo, address, copyright, footerLinks, parentLogo, socialIcons, socialIconsLabel, parentlogolink }) => {

    const [socialIconList, setSocialIconList] = useState([]);
    const [footerLinkList, setFooterLinkList] = useState([]);

    useEffect( () => {
        async function fetchFooterData() {
            setTimeout(async () => {
                const _socialIconList = await agility.client.getContentList(socialIcons.referencename);
                setSocialIconList(_socialIconList.items);
                const _footerLinkList = await agility.client.getContentList(footerLinks.referencename);
                setFooterLinkList(_footerLinkList.items);
            }, 400);
        }
        fetchFooterData();
    }, []);

    return (
        <footer>
            { !isContactPage && <FooterConnect connect_bgImg={connect_bgImg.url} leasingDetails={leasingDetails} {...connectLink}/> }
            <div className="footer_bottom">
                <div className="footer_bottom_inner">
                    <div className="footer_logo">
                        <img src={`${logo.url}`} alt=""/>
                        <span className="footer_address"  dangerouslySetInnerHTML={{__html: address}} ></span>
                    </div>
                    <div className="footer_bottom_social">
                        <span className="social_icon_lable">{socialIconsLabel}</span>
                        {socialIconList && <div className="social_icons">
                            {socialIconList.map( icon => 
                                <a key={icon.contentID} href={icon.fields.link.href} target={icon.fields.link.target}>
                                    <img src={`${icon.fields.image.url}`}></img>
                                </a>)}
                        </div> }
                    </div>
                    <div className="footer_bottom_parentlogo">
                        <a href={parentlogolink.href} alt="" target={parentlogolink.target}><img src={`${parentLogo.url}?w=180px`} alt=""/></a>
                    </div>
                    <div className="footer_copyright">
                        {copyright}
                    </div>
                    {footerLinkList && <div className="footer_links">
                        {footerLinkList.map( link => 
                            <a key={link.contentID} href={link.fields.link.href} target={link.fields.link.target}>{link.fields.title}</a>)}
                    
                    <div id="teconsent"></div>
                    </div> } 
                </div>
            </div>
        </footer>
    );
}

export default GlobalFooter;
