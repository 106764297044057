import React, {useState} from 'react';
import close from './close.svg';

import './ZoomableAsset.scss';


const ZoomableAsset = ({filesize, height, label, pixelHeight, pixelWidth, target, url, width, modalImage, setModalImage, setZoom, zoomImage }) => {
    const imageReg = /\.(gif|jpg|jpeg|tiff|png)$/i;
    const videoReg = /\.(mp4)$/i;
    const isImage = imageReg.test(url);
    const isVideo = videoReg.test(url);

    const hasZoom = zoomImage === 'false' ? false : true;

    return (
        <figure className={`zoom-${!!modalImage}`}>
            { isImage && hasZoom && <><img onClick={ e => {
                    setModalImage({url, target, label, pixelHeight, pixelWidth});
                    setZoom(1);
                }} src={`${url}?w=600`} target={target} title={label} alt={label} />
                <div onClick={ e => {
                        setModalImage({url, target, label, pixelHeight, pixelWidth});
                        setZoom(1);
                    }} className="zoom-button"><img src={close} alt=""/></div>
                </>}
            { isImage && !hasZoom && <img src={url} target={target} title={label} alt={label} />}
            {
                isVideo && <video controls muted autoPlay={true}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            }
            
        </figure>
    );
    

}


export default  ZoomableAsset;
