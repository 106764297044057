import React, {useState, useEffect} from 'react';
import Sticky from 'react-sticky-el';

const DESKTOP_HEADER_HEIGHT = 212;
const MOBILE_HEADER_HEIGHT = 130;

function string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

const JumplinksBar = ({jumplinks}) => {
    const [current, setCurrent] = useState(0);
    const [isMobile, setMobile] = useState(false);
    
    const header_height = isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT;
    useEffect(() => {
        setMobile(!!window && window.innerWidth < 1000 );
    }, [])
    return (<Sticky topOffset={isMobile ? -84 : -162}>
        <section className="JumplinksBar">
            <div className="xcontainer">
                {jumplinks.map((j,idx) => <a key={j.item.contentID} onClick={ e => {
                    e.preventDefault();
                    const target = document.getElementById(`jumplink-${string_to_slug(j.item.fields.title)}`);
                    setCurrent(idx);
                    window.scrollTo({top:target.offsetTop - header_height, behavior:'smooth'});
                }}
                id={`tojumplink-${string_to_slug(j.item.fields.title)}`}
                className={`singlejumplink current-${current == idx}`}
                style={{
                    width: `calc(100% / ${jumplinks.length})`
                }}
                >{j.item.fields.title}</a>)}
            </div>
        </section>
    </Sticky>)
}

export default JumplinksBar;
