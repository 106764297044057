import React from 'react';
import './RichTextArea.scss';

const RichTextArea = ({textblob, referenceName}) => {
    return (<div className={`row ${referenceName}`}>
        <div dangerouslySetInnerHTML={{__html: !!textblob ? textblob : 'test'}}></div>
    </div>)
}

export default RichTextArea;
