import React, {Component} from 'react';

//import './style.scss';



class Raw extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    // console.log(this.props);
  }
  render() {
    return <div className="raw">
      <strong>Component {this.props.componentName}</strong> is not available in React's context. It is defined in agility, however. Please check React Dev tools for props.
      <code>{JSON.stringify(this.props)}</code>
    </div>;
  }
}

export default Raw;
