import React, { useState } from 'react';
import './Connect.scss';

const Input = ({label, validateBy=val=>true, errorText, value, onChange, onError=()=>true }) => {
    const [error, hasError] = useState(false);
    return <div className={`input error-${error}`}>
        <input type="text" value={value} 
            placeholder={label}
            onChange={e => {
            hasError(!validateBy(e.target.value));
            onError(!validateBy(e.target.value));
            onChange(e.target.value);
            }} />
        <label>{label}</label>
        {error && <div className="error">{errorText}</div>}
    </div>
  }


const Connect = ({ content, formEmbed }) => {
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState(true);

    return (
        <section className="connect">
            <div className="connect_inner">
                <div className="connect_content">
                    <div className="connect_content_data" dangerouslySetInnerHTML={{__html: content}} />
                </div>
                <div className="connect_form">
                    <div className="connect_content_data" dangerouslySetInnerHTML={{__html: formEmbed}} />
                    <div className="form-container">
                        <form id="contactForm">
                            <Input 
                                label="Full name*" 
                                errorText="This field is required." 
                                value={fname} 
                                onChange={setFname} 
                                validateBy={ v => !!v } 
                                onError={setError}
                            />
                            <Input 
                                label="Company" 
                                errorText="This field is required." 
                                value={company} 
                                onChange={setCompany} 
                                validateBy={ v => !!v } 
                                onError={setError}
                            />
                            <Input 
                                label="Email*" 
                                errorText="This field is required and needs to be a valid email address." 
                                value={email} 
                                onChange={setEmail} 
                                validateBy={ v => /\S+@\S+\.\S+/.test(v) } 
                                onError={setError}
                            />
                            <Input 
                                label="Phone" 
                                // errorText="This field is required and must be a valid phone number." 
                                value={phone} 
                                onChange={setPhone} 
                                // validateBy={ v => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v)} 
                                onError={setError}
                            />
                            
                            <div className="checkbox">
                                <input type="checkbox" id="form-confirm" checked={checked} onChange={e => setChecked(!checked)} />
                                <label htmlFor="form-confirm"><small>By submitting this contact form, you agree that a representative(s) of Oxford Properties may contact you using the information you provided. In accordance with our Privacy Policy, we will never share or sell your personal data. </small></label>
                            </div>

                            <div className="submit">
                                <input disabled={!!error && !checked} type="submit" value={`Submit`}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Connect;
