import React, {useState, useEffect} from 'react';
import './Features.scss';

const Features = ({agility, availabilityDate, backgroundImage={}, componentName, content, contentID, definitionName, features, itemOrder, modified, referenceName, state, title, versionID}) => {
    const [featureList, setFeatureList] = useState(false);
    useEffect( () => {
        async function fetchFeatureList() {
            if (typeof window !== 'undefined')   {
                var x = setTimeout(async function() {
                    if (!!featureList ) {
                    } else if (!!window._riverbend && !featureList) {
                        const _featureList = await agility.client.getContentList(features.referencename)
                        setFeatureList(_featureList.items);
                    }
                    else {
                        setFeatureList(features);
                    }
                }, 500);
            }
        }
        fetchFeatureList();
    }, []);

    return (<section className="features">
        <figure className="features_background">
            <img src={`${backgroundImage.url}?w=1400`} alt=""/>
        </figure>
        <div className="features_availability">
            <div className="features_availability_inner">{availabilityDate}</div>
        </div>
        <div className="features_content">
            <div className="features_content_inner" dangerouslySetInnerHTML={{__html: content}} />
        </div>
        <div className="features_details">
            <h3 className="features_details_heading">{title}</h3>
            {featureList && <ul className="features_details_list">
                {featureList.map( feature => <li className="features_details_list_item" key={feature.contentID}>
                    <span className="features_detail_title">{feature.fields.title}</span>
                    <span className="features_detail_description">{feature.fields.description}</span>
                </li>)}
            </ul>  }
        </div>
    </section>)
}

export default Features;
