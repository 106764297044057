import React from 'react';
import './TitleandTextWithAsset.scss';
import ZoomableAsset from './../ZoomableAsset';

const TitleandTextWithAsset = ({title, asset, layout, content, modalImage, setModalImage, setZoom, zoomImage }) => {
    // layout = offset, Right, Left, background
    return(
        <section className={`title_text_asset ${layout}`}>
            {!!title && <div className="title_text_asset_title">
                <div className="title_text_asset_title_inner">
                    <h2>{title}</h2>
                </div>
            </div>}
            <div className="title_text_asset_content">
                <div className="title_text_asset_content_inner" dangerouslySetInnerHTML={{__html: !!content ? content : ''}}></div>
            </div>
            <div className="title_text_asset_asset">
                <div className="title_text_asset_asset_inner">
                    <ZoomableAsset  zoomImage={zoomImage} modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} {...asset} />
                </div>
            </div>
        </section>
    );
}

export default TitleandTextWithAsset;
