import React, { useState, useEffect } from 'react';
import Carousel from 'nuka-carousel';

import './HeroSlider.scss';
import { agility } from '@agility/content-fetch';

const HeroSliderPageTitle = ({ titleImage }) => {
    return(
        <div className="heroslider_title">
            <img src={`${titleImage}`} alt=""/>
        </div>
    );
}

const HeroSlider = ({ images, titleImage }) => {
    const [slideImages, setSlideImages] = useState(false);
    const [slidesReady, setSlidesReady] = useState(false);

    useEffect(() => {
        async function fetchSlideImages() {
            if (window._riverbend)   {
                const _slideImages = await agility.client.getContentList(images.referencename);
                setSlideImages(_slideImages.items);
            }
            else {
                setSlideImages(images);
            }
        }
        fetchSlideImages();
    }, []);

    const _handleLoadImage = () => {
        setSlidesReady(true);
    }

    const defaultControlsConfig={
        pagingDotsClassName: 'custom_paging_dot',
        pagingDotsStyle: {
          fill: 'white'
        }
      }
    
    return(
        <section className={`hero_slider animate-${slidesReady} ${titleImage ? 'title-true' : ''}`}>
            { titleImage && <HeroSliderPageTitle titleImage={titleImage.url}/> }
            {slideImages && <Carousel
                                autoplay={!!slideImages && slideImages.length > 1}
                                autoplayInterval={3000}
                                dragging={true} 
                                swiping={true} 
                                wrapAround={true} 
                                defaultControlsConfig={defaultControlsConfig}>
                {
                    slideImages &&  
                        slideImages.map( item => <img key={item.contentID} onLoad={_handleLoadImage} src={`${item.fields.image.url}`}></img>)
                }
            </Carousel>}
        </section>
    );
}
export default HeroSlider;
