import React, { useState, useEffect } from 'react';
import ZoomableAsset from './../ZoomableAsset';
import './FloorDetails.scss';

const FloorDetails = ({agility, features, title, image, bottomImage, layout, modalImage, setModalImage, setZoom }) => {
    const [featuresList, setFeaturesList] = useState(false);
    useEffect( () => {
        async function fetchFeaturesData() {
            if (typeof window !== 'undefined')   {
                var x = setTimeout(async function() {
                    if (!!featuresList ) {
                    } else if (!!window._riverbend && !featuresList) {
                        const _featuresList = await agility.client.getContentList(features.referencename);
                        setFeaturesList(_featuresList.items);
                    }
                    else {
                        setFeaturesList(features);
                    }
                }, 500);
            }
        }
        fetchFeaturesData();
    }, []);
    
    return (<>
        <section className="floor_details mobile">
            <h2 className="floor_details_title">{title}</h2>
            <figure className="floor_top_img">
                <ZoomableAsset  modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} {...image} />
            </figure>
            <figure className="floor_bottom_img">
                <ZoomableAsset  modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} {...bottomImage} />
            </figure>
            <div className="floor_details_features">
                {featuresList && <ul className="features_details_list">
                    {featuresList.map( feature => <li className="features_details_list_item" key={feature.contentID}>
                        <span className="features_detail_title">{feature.fields.title}</span>
                        <span className="features_detail_description">{feature.fields.description}</span>
                    </li>)}
                </ul>  }
            </div>
        </section>
        <section className={`floor_details desktop ${layout}`}>
            <div className={`container ${layout}`}>
                <div className="floor_details_content">
                    <h2 className="floor_details_title">{title}</h2>
                    <div className="floor_details_features">
                        {featuresList && <ul className="features_details_list">
                            {featuresList.map( feature => <li className="features_details_list_item" key={feature.contentID}>
                                <span className="features_detail_title">{feature.fields.title}</span>
                                <span className="features_detail_description">{feature.fields.description}</span>
                            </li>)}
                        </ul>  }
                    </div>
                </div>
                <div className="floor_details_images">
                    <figure className="floor_top_img">
                        <ZoomableAsset  modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} {...image} />
                    </figure>
                    <figure className="floor_bottom_img">
                        <ZoomableAsset  modalImage={modalImage} setModalImage={setModalImage} setZoom={setZoom} {...bottomImage} />
                    </figure>
                </div>
            </div>
        </section>
    </>);
}

export default FloorDetails;
