import React, { useState } from 'react';
import './PageTitle.scss';

const PageTitle = ({backgroundImage, titleOverride}) => {
    const [ imagesReady, setImagesReady ] = useState(false);
    const _handleLoadHeader = () => { setImagesReady(true);}

    const oneLineTitle = titleOverride.height === 308 ? 'oneline' : '';
    const isHigherLineTitle = titleOverride.label === 'the-park-page-title' ? 'higher-line': '';

    return (<section className={`animate-${imagesReady} pagetitle ${oneLineTitle} ${isHigherLineTitle}`} onLoad={_handleLoadHeader}>
        <figure className="pagetitle_title">
            <img src={`${titleOverride.url}?w=2000`} alt=""/>
        </figure>
        <figure className="pagetitle_background">
            <img src={`${backgroundImage.url}?w=2000`} alt=""/>
        </figure>
    </section>);
}

export default PageTitle;
