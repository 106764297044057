import React, { useState, useEffect } from 'react';
import './TitlewithImageandLegend.scss';

const TitlewithImageandLegend = ({agility, contentID, images, legend, title, referenceName }) => {
    const [imageList, setImageList] = useState(false);

    useEffect( () => {
        async function fetchImages() {
            if (typeof window !== 'undefined')   {
                var x;
                x = setTimeout(async function() {
                    if (!!window._riverbend) {
                        const _imageList = await agility.client.getContentList(referenceName);
                        setImageList(_imageList.items);
                    }
                    else {
                        setImageList(images);
                    }
                }, 1000);
            }
        }
        fetchImages();
    }, []);

    return(
        <section className="title_image_legend">
            <div className="title_image_legend_header">
                <div className="title"><h2>{title}</h2></div>
                <figure className="legend"><img src={legend.url} alt=""/></figure>
            </div>
            { imageList && <div className="images_list">
                { imageList.map( img => {
                    //TODO: can probably replace this to its existing code
                    let url = "";
                    
                    if (typeof img.fields.image !== "undefined") {
                        url = img.fields.image.url;
                    }
                    else {
                        url = img.fields.legend.url;
                    }

                        return <img key={img.contentID} src={`${url}`}></img>
                    }
                )}
            </div> }
        </section>
    );
}

export default TitlewithImageandLegend;




