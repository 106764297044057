import React, { Component } from 'react';
import queryString from 'query-string'
import agilityConfig from './agility.config'
import agilityContentFetch from '@agility/content-fetch'
import App from './App'

class AgilityApp extends Component {

    constructor(props) {
        super(props)

        this.agility = {
            client: this.createClient(),
            config: agilityConfig,
            onPageRoutingError: this.onPageRoutingError,
            onPageNotFound: this.onPageNotFound
        }
        this.state={
            pagedata:null
        };
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    async setup(api){
        
        let sitemap = await api.getSitemap();
        let sitemapNested = await api.getSitemapNested();
        const newslist = await api.getContentList('news');

    // handle redirects with contentID in query param
    //CHEN: Not sure where move this to...
    /*
        const contentIdRedirect = siteMapItemFromContentId(sitemap, req.query.contentID);
        if (!!contentIdRedirect) {
            res.redirect(contentIdRedirect.path);
            return;
        }
        */
        let globalHeader =  await api.getContentItem(21);
        let globalFooter = await api.getContentItem(112);

        let currentUrlSlug = window.location.pathname;//req ? req.path.toLowerCase() : pathname;
        currentUrlSlug = (currentUrlSlug === '/' || currentUrlSlug === '') ? '/home' : currentUrlSlug;
        if (currentUrlSlug[currentUrlSlug.length - 1] == '/') {
          currentUrlSlug = currentUrlSlug.substring(0, currentUrlSlug.length - 1);
        }
        let thisSiteMapItem = sitemap[currentUrlSlug];
        let isContactPage = currentUrlSlug === '/contact' 

        // REDIRECT TO 404 - look into withRouter
        //Chen: Not sure where to put this.
        /*if (typeof thisSiteMapItem == 'undefined') {
            thisSiteMapItem = {
                redirect: {url: options.error404}
            }
        }
    
        // HANDLE REDIRECTS and Do not process if not needed
        if (!!thisSiteMapItem.redirect) {
            let redirectLocation = thisSiteMapItem.redirect.url.replace('~', '');
            res.redirect(redirectLocation);
            return;
        }
        */
        var currentPage = await api.getPage(thisSiteMapItem.pageID);

        // HANDLE Dynamic Content
        if (!!currentPage.dynamic && !!thisSiteMapItem.contentID) {
      //console.log(thisSiteMapItem.contentID);
            currentPage.thisDynamicItem = await api.getContentItem(thisSiteMapItem.contentID);
        }

        let options = {
          error500: '/errors/500',
          error404: '/errors/404',
          zoneHandlers: {
            MainContent: (zone, api) => {
            return zone;
            },
            Masthead: (zone, api, sitemapNested, thisSiteMapItem) => { 
            return zone;
            }
        },
        moduleHandlers: {
            Heading: (module, api) => {
            return module;
        }
        },
    };
  
    // get the handlers working
    if (!!currentPage.zones) 
    {
         await Promise.all(Object.keys(currentPage.zones).map(async(zoneKey, zoneIndex) => {
            let zone = currentPage.zones[zoneKey];
            if (typeof options.zoneHandlers[zoneKey] == 'function') {
                zone = await options.zoneHandlers[zoneKey](zone, api, sitemapNested, thisSiteMapItem);
            }
            await Promise.all( zone.map(async agilityModule => {
                if (options.moduleHandlers[agilityModule.module]) {
                    await options.moduleHandlers[agilityModule.module](agilityModule, api);
                }   
            }));
        }));
    } 
        
    
    // Handle unset titles 
        if (!!!thisSiteMapItem.title || thisSiteMapItem.title == '[empty]') {
            thisSiteMapItem.title = false;
        }

        const isPreview = false;
    //console.log('all done on the server');
    // setup Pagedata
        const pagedata = {
            currentPage,
            sitemap,
            thisSiteMapItem,
            sitemapNested,
            isPreview: isPreview,
            globalHeader,
            globalFooter,
            newslist,
            isContactPage
        };
       // next();
        return pagedata;
  }
    componentDidMount(){
        const self = this;
        let setter = function(data){
            self.setState({pagedata:data});
        }
        this.setup(self.agility.client).then(setter);
    }
    
    createClient() {

        //check whether preview has been enabled via query string / local storage
        this.checkPreviewModeAndLanguageCode();

        //build the client
        let apiKey = agilityConfig.fetchAPIKey;
        if (agilityConfig.isPreview) {
            apiKey = agilityConfig.previewAPIKey;
        }

        const api= agilityContentFetch.getApi({
            guid: agilityConfig.guid,
            apiKey: apiKey,
            caching: agilityConfig.caching,
            isPreview: agilityConfig.isPreview
        })
        return {
            api:api,
            
            getSitemap : items => {
            return Promise.resolve(
                api.getSitemapFlat({
                channelName: 'website',
                languageCode: 'en-us'
            })
            .then(sitemap => sitemap)
            .catch(error => error.data)
        );
      },
  
  
  
      getSitemapNested : () => {
        return Promise.resolve(
          api.getSitemapNested({
            channelName: 'website',
            languageCode: 'en-us'
          })
          .then(sitemap => sitemap)
          .catch(error => error.data)
        )
      },
      
      getPage : (pageID) => {
        return Promise.resolve(
          api.getPage({
            pageID: pageID,
            languageCode: 'en-us',
            contentLinkDepth: 16
          })
            .then(page => page)
            .catch(error => error.data)
        )
      },
      
      getContentItem : (contentID) => {
  
        return Promise.resolve(
          api.getContentItem({
            contentID: parseInt(contentID),
            languageCode: 'en-us',
            contentLinkDepth: 16
          })
            .then(contentItem => contentItem)
            .catch(error => error.data)
        )
      },
      
      getContentList : (referenceName, skip=0) => {
        return Promise.resolve(
          api.getContentList({
            referenceName: referenceName,
            languageCode: 'en-us',
            contentLinkDepth: 15,
            take: 50,
            skip: skip,
            sort: 'properties.created',
            direction: api.types.SortDirections.ASC
          })
            .then( contentList => contentList )
            .catch(error => error.data)
        );
      },
  
      getTimeLine : (page=0) => {
        return Promise.resolve(
          api.getContentList({
            referenceName: 'news',
            languageCode: 'en-us',
            contentLinkDepth: 15,
            take: 50,
            skip: 0,
            sort: 'properties.created',
            filters: [
              {property: 'fields.timelineHidden', operator: api.types.FilterOperators.NOT_EQUAL_TO, value: true},
            ],
            direction: api.types.SortDirections.DESC
          })
            .then( contentList => contentList )
            .catch(error => error.data)
        );
      }

        }
    }

    /**
     * Check whether the site is in preview mode, and also set the language     
     *      
     * @memberof AgilityApp
     */
    checkPreviewModeAndLanguageCode() {

        //kick out if we don't have a docuemnt
        if (!document) return;


        //lang=en-us                --set the language code
        //agilitypreviewkey=xyz     --set previewMode = true
        //AgilityPreview=0          -- set previewMode = false
        const parsed = queryString.parse(document.location.search);
        let storageKey = "agility-config";
        let storedConfigWasAltered = false;

        let storedConfig = {
            languageCode: agilityConfig.languageCode,
            isPreview: agilityConfig.isPreview
        };


        if (window && window.sessionStorage) {
            //pull the stored config values from local storage if we can
            let str = window.sessionStorage.getItem(storageKey);
            if (str && str.length > 4) {
                try {
                    let newConfig = JSON.parse(str);

                    if (newConfig && newConfig.languageCode) {
                        storedConfig = newConfig;
                    }
                } catch (parseError) { }
            }
        }

        if (parsed.lang && parsed.lang.length > 0) {

            if (storedConfig.languageCode !== parsed.lang) {
                if (console) console.log("[Agility] Setting language code to", parsed.lang);
                storedConfig.languageCode = parsed.lang;
                storedConfigWasAltered = true;
            }
        }
        if (parsed.AgilityPreview && parsed.AgilityPreview === "0") {
            if (storedConfig.isPreview !== false) {
                if (console) console.log("[Agility] Setting preview mode to false");
                storedConfig.isPreview = false;
                storedConfigWasAltered = true;
            }

        } else if (parsed.agilitypreviewkey) {
            if (storedConfig.isPreview !== true) {
                if (console) console.log("[Agility] Setting preview mode to true");
                storedConfig.isPreview = true;
                storedConfigWasAltered = true;
            }
        }


        //set the config vals
        agilityConfig.languageCode = storedConfig.languageCode;
        agilityConfig.isPreview = storedConfig.isPreview;

        if (storedConfigWasAltered) {
            if (window && window.sessionStorage) {
                //put the value in storage
                window.sessionStorage.setItem(storageKey, JSON.stringify(storedConfig));
            }
        }

    }

    onPageRoutingError = (errorMsg, error) => {
        console.error(errorMsg, error);
    }

    onPageNotFound = (errorMsg) => {
        console.warn(errorMsg);
    }

    render() {
        if (!this.state.pagedata) {
            return (<div></div>);
        }
        
        return (
            <App agility={this.agility} {...this.state.pagedata} />
        );
    }
}

export default AgilityApp;
