import React from "react";
import Head from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const SEO = (props) => {
    // setup and parse additional header markup
    const { title, metaDescription, metaKeywords, ogImage, metaHTML } = props
    const propertyName = props.zones?.MainContentZone[0]?.data?.property_name
    const complexName = props.zones?.MainContentZone[0]?.complexData?.fields?.title
    const titleFromSEO = complexName ? complexName : title
    const broswerTitle = (propertyName) ?  propertyName :  titleFromSEO
    
    let additionalHeaderMarkup = null;
    if (metaHTML) {
        additionalHeaderMarkup = ReactHtmlParser(metaHTML);
    }
    return (
        <Head>
            <title>{broswerTitle} | Oxford Properties Group</title>
            <meta name="generator" content="Agility CMS" />
            <meta name="agility_timestamp" content={new Date().toLocaleString()} />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap"
                rel="stylesheet"
            />
            {ogImage && <meta property="og:image" content={ogImage} />}
            {additionalHeaderMarkup}
        </Head>
    );
};

export default SEO;
