import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie'
import "./CookieFooter.scss";

const CookieFooter = ({
  buttonText,
  privacyStatementLink,
  description,
  title,
}) => {

  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    let riverCookie = Cookies.get('riverbend_accept_cookie');
    if (riverCookie === 'yes') {
      setShowBanner(false)
    }
  }, [showBanner])
  

  const onClick = (e) => {
    Cookies.set('riverbend_accept_cookie', 'yes', { expires: 30, path: '/' })
    setShowBanner(false)
  }

  return (
    <div>
    { showBanner ? 
      <section className="cookie-footer">
        <div className="cookie-container">
          <div className="cookie-title">
            <div className="cookie-title-holder">{title}</div>
          </div>
          <div className="cookie-description">
            {description}&nbsp;
            <a href={privacyStatementLink.href}>Privacy Statement</a>
          </div>
          <div className="cookie-button">
            <button onClick={onClick} className="cookie-button-holder">{buttonText}</button>
          </div>
        </div>
      </section> 
    : null }
    </div>
  );
};

export default CookieFooter;
